<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Templates <span>Create Category</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <Form @submit="handleCreateCategory" v-slot="{ errors }" ref="template-create-category-form">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Type</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.is_content }">
                                    <Field autocomplete="off" name="is_content" v-model="createForm.is_content" rules="required" label="type">
                                        <multiselect
                                            v-model="createForm.is_content"
                                            :options="categoryType"
                                            placeholder="Select type">
                                        </multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="is_content" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Category Name</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.type }">
                                    <Field autocomplete="off" type="text" name="type" v-model="createForm.type" placeholder="ex: New Category" rules="required" label="name" />
                                </div>
                                <ErrorMessage name="type" class="text-danger" />
                            </div>
                        </div>
                        <div class="action_wpr">
                            <button type="submit" :disabled="loader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Adding' : 'Add' }}</button>
                        </div>
                    </Form>
                    <ul class="custom_list">
                        <li v-for="(category, c) in categories" :key="c" class="selection">
                            <h5>
                                <i class="fas fa-bars"></i>
                                <label :id="`edit-category-${category.id}`" :contenteditable="selectedCategory.includes(category.id) ? 'true' : 'false'" :class="{'pointer' : !selectedCategory.includes(category.id)}">
                                    {{ category.type }}
                                </label>
                                <span v-if="selectedCategory.includes(category.id)" class="save" @click="handleEditCategory(category)">save</span>
                                <button type="button" class="ml-auto" @click="selectedCategory.push(category.id)"><i class="far fa-edit"></i></button>
                                <button type="button" class="danger" @click="handleDeleteCategory(category)"><i class="fas fa-trash-alt"></i></button>
                            </h5>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="modal_footer">
                <button type="button" :disabled="loader" class="btn cancel_btn" @click="closeModal()">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { Field, Form, ErrorMessage } from 'vee-validate'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'

    export default {
        name: 'Template Create Category',

        data () {
            return {
                categoryType: [
                    { value: 1, label: 'Email' },
                    { value: 3, label: 'SMS' },
                    { value: 4, label: 'Voice' },
                    { value: 2, label: 'Content' },
                ],
                createForm: {
                    is_content: '',
                    type: '',
                },
                selectedCategory: [],
            }
        },

        props: {
            modelValue: Boolean,
        },

        emit: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            modelValue (val) {
                if (val) {
                    const vm = this;

                    vm.resetForm();
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: {
            ...mapState ({
                categories: state => state.templateModule.categories,
                loader: state => state.templateModule.templateComponentLoader,
            })
        },

        methods: {
            ...mapActions({
                getCategories: 'templateModule/getCategories',
                createCategory: 'templateModule/createCategory',
                editCategory: 'templateModule/editCategory',
                deleteCategory: 'templateModule/deleteCategory',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleCreateCategory (form, { setFieldError }) {
                const vm = this;
                vm.createForm.setFieldError = setFieldError;

                vm.createCategory(vm.createForm).then((result) => {
                    if (result) {
                        vm.getCategories();
                        vm.resetForm();
                    }
                });
            },

            resetForm () {
                const vm = this;

                let resetForm = setInterval(() => {
                    if (vm.$refs['template-create-category-form']) {
                        vm.$refs['template-create-category-form'].resetForm();
                        clearInterval(resetForm);
                    }
                }, 10);

                vm.selectedCategory = [];
            },

            handleEditCategory (category) {
                const vm = this;

                const activeCategory = document.getElementById(`edit-category-${category.id}`);

                if (!activeCategory.innerText.length) {
                    return Toastr.error('Category name is required');
                }

                const editForm = {
                    id: category.id,
                    type: activeCategory.innerText,
                    is_content: category.is_content,
                }

                vm.editCategory(editForm).then((result) => {
                    if (result == true) {
                        vm.selectedCategory.splice(vm.selectedCategory.indexOf(category.id), 1);
                    } else if (result.response.status == 422) {
                        if (result.response.data && result.response.data.errors) {
                            if (result.response.data.errors.type) {
                                Toastr.error(result.response.data.errors.type[0]);
                            } else {
                                Toastr.error('The category name already taken!');
                            }
                        } else {
                            Toastr.error('The category name already taken!');
                        }

                    } else {
                        Toastr.handleServerError(result);
                    }
                });
            },

            handleDeleteCategory (category) {
                const vm      = this;
                const options = Helper.swalConfirmOptions('Are you sure?', `This is permanent. Any content in this category will be moved to the "Unassigned" category.`, 'Delete');

                options.preConfirm = function () {
                                        return vm.deleteCategory({ id: category.id, category: category.type }).then((result) => {
                                            if (result) {
                                                vm.getCategories();
                                            }
                                        });
                                    };

                Swal.fire(options);
            },
        }
    }
</script>

<style scoped>
    .custom_list {
        margin-top: 40px;
    }

    .custom_list li {
        border: 1px solid #eaeaea;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        border-radius: 6px;
        padding: 10px 15px;
        margin-bottom: 15px;
    }

    .custom_list li.selection {
        cursor: pointer;
    }

    /* .custom_list li.selection:active {
        border: 1px solid #999;
    } */

    .custom_list li h5 {
        display: flex;
        align-items: center;
        font-weight: 500;
    }

    .custom_list li h5 label {
        padding: 5px 10px;
        cursor: auto;
    }

    .custom_list li h5 span.save {
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
    }

    .custom_list li h5 button {
        font-size: 14px;
        margin: 5px 7px;
        cursor: pointer;
    }

    .custom_list li .section_wpr {
        border-top: 1px solid #eaeaea;
        margin: 10px -15px 0 -15px;
        padding: 10px 30px;
    }
</style>
